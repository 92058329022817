<template>
  <div>
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <EmptyStates title="No content yet"/>
  </div>
</template>

<script>

// import HelloWorld from '@component/HelloWorld.vue'
import EmptyStates from "@component/EmptyStates";
export default {
  name: 'Home',
  components: {
    // HelloWorld,
    EmptyStates
  },
  methods: {
  }
};
</script>

<style lang="scss"></style>
